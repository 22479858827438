import { useEffect } from 'react';

const useResizeScreen = (action: () => void) => {
  useEffect(() => {
    action();
    // timeoutId for debounce mechanism
    let timeoutId: undefined | number;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = window.setTimeout(() => action(), 150);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, []);
};

export default useResizeScreen;
