import { loader } from 'graphql.macro';

import type { QueryHook } from 'api/types/hooks/query';

import { useQuery } from 'lib/apollo/hooks';

import type {
  OneStepApplicationFormQueryData,
  OneStepApplicationFormQueryOriginData,
  OneStepApplicationFormQueryVariables,
} from './types';
import { QUERY_NAME } from './types';

const OneStepApplicationFormQuery = loader('./oneStepApplicationForm.graphql');

const getData = (data?: OneStepApplicationFormQueryOriginData) => data?.[QUERY_NAME];

const useOneStepApplicationFormQuery: QueryHook<
  OneStepApplicationFormQueryOriginData,
  OneStepApplicationFormQueryVariables,
  OneStepApplicationFormQueryData
> = ({ onCompleted, ...options } = {}) => {
  const { data, ...result } = useQuery(
    OneStepApplicationFormQuery,
    {
      fetchPolicy: 'cache-first',
      ...options,
      onCompleted: onCompleted ? (completedData) => onCompleted(getData(completedData)) : undefined,
    },
    {
      showGeneralError: false,
    },
  );

  return {
    ...result,
    data: getData(data),
  };
};

export default useOneStepApplicationFormQuery;
