import CircularProgress from '@mui/material/CircularProgress';

import Loader from 'components/ui/molecules/Loader';

const LoaderSpinner = () => (
  <Loader>
    <CircularProgress />
  </Loader>
);

export default LoaderSpinner;
