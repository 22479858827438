import { cloneElement, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';

import useCheckIfFrameMode from 'hooks/useCheckIfFrameMode';
import useResizeScreen from 'hooks/useResizeScreen';

import noop from 'lib/utils/noop';
import sendToIframe from 'lib/utils/sendToIframe';

import Logo from 'assets/icons/logoBlue.svg';

import Error from './Error';
import { Content, Header, LogoLink, MenuLink, Wrapper } from './styled';
import type { ApplyTemplateProps, Errors } from './types';

const ApplyTemplate = ({ content, resetForm }: ApplyTemplateProps) => {
  const iframeMode = useCheckIfFrameMode();

  const [errors, setErrors] = useState<Errors>(null);

  const websiteLink = process.env.REACT_APP_PUBLIC_WEBSITE_LINK;

  const wrapperRef = useRef(null);
  const updateIframe = iframeMode ? () => sendToIframe(wrapperRef) : noop;
  useResizeScreen(updateIframe);

  useEffect(() => {
    updateIframe();
  });

  const contentProps = {
    updateIframe,
    wrapperRef,
    setErrors,
  };

  return (
    <Wrapper ref={wrapperRef}>
      {!iframeMode && (
        <Header>
          <LogoLink href={websiteLink}>
            <img src={Logo} alt="Logo" width={91} height={48} />
          </LogoLink>
          <Box ml={8}>
            <MenuLink href={websiteLink}>Home</MenuLink>
          </Box>
        </Header>
      )}
      <Content>
        {!errors && content ? (
          cloneElement(content, contentProps)
        ) : (
          <Error errors={errors} setErrors={setErrors} onClick={resetForm} />
        )}
      </Content>
    </Wrapper>
  );
};

export default ApplyTemplate;
