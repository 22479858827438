import type { MutableRefObject } from 'react';

const isInIframe = () => window.parent !== window;

const sendToIframe = (ref: MutableRefObject<HTMLDivElement | null>) => {
  if (!isInIframe() || !ref.current) return;

  const { scrollHeight, scrollWidth } = ref.current;

  window.parent?.postMessage({ sender: 'pmf-apply-form-iframe', height: scrollHeight, width: scrollWidth }, '*');
};

export default sendToIframe;
