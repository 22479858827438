import { useCallback, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { MODE, ORIGIN, THANK_YOU_PAGE_URL, TOS_URL, UUID } from 'config/constants/queryParams';
import { SINGLE_STEP_APPLY } from 'config/routes';

import useCheckIfFrameMode from 'hooks/useCheckIfFrameMode';
import useReferralId from 'hooks/useReferralId';

import { NotifierProvider } from 'contexts/NotifierContext';
import ThemeProvider from 'contexts/ThemeContext';

import Notifier from 'components/ui/atoms/Notifier';

import ApplyTemplate from '../components/ApplyTemplate';
import ApplyContent from './components/ApplyContent';
import getSingleStepApplyFormReferralIdFromOrigin from './getSingleStepApplyFormReferralIdFromOrigin';

const SingleStepApply = () => {
  const [searchParams] = useSearchParams();
  const mode = searchParams.get(MODE);
  const uuid = searchParams.get(UUID);
  const origin = searchParams.get(ORIGIN);
  const termsOfServiceUrl = searchParams.get(TOS_URL);
  const thankYouPageUrl = searchParams.get(THANK_YOU_PAGE_URL);

  const referralId = useReferralId(getSingleStepApplyFormReferralIdFromOrigin);
  const iframeMode = useCheckIfFrameMode();

  const params = new URLSearchParams();
  if (mode) params.set(MODE, mode);
  if (origin) params.set(ORIGIN, origin);
  if (termsOfServiceUrl) params.set(TOS_URL, termsOfServiceUrl);
  if (thankYouPageUrl) params.set(THANK_YOU_PAGE_URL, thankYouPageUrl);

  const location = `${SINGLE_STEP_APPLY}/${referralId || ''}?${params.toString()}`;
  const navigate = useNavigate();

  useEffect(() => {
    if (location && iframeMode && !uuid) navigate(location);
  }, [location, mode, iframeMode]);

  const termsOfServiceUrlRef = useRef('');
  const thankYouPageUrlRef = useRef('');

  useEffect(() => {
    if (termsOfServiceUrl) termsOfServiceUrlRef.current = termsOfServiceUrl;
  }, [termsOfServiceUrl]);

  useEffect(() => {
    if (thankYouPageUrl) thankYouPageUrlRef.current = thankYouPageUrl;
  }, [thankYouPageUrl]);

  const resetForm = useCallback(async () => {
    let locationForReset = `${SINGLE_STEP_APPLY}/${referralId || ''}`;
    if (mode) locationForReset += `?${MODE}=${mode}`;
    navigate(locationForReset);

    window.location.reload();
  }, [referralId, mode]);

  return (
    <ThemeProvider>
      <NotifierProvider>
        <ApplyTemplate
          resetForm={resetForm}
          content={
            // @ts-expect-error Some props are sent from ApplyTemplate
            <ApplyContent /* termsOfServiceUrl={termsOfServiceUrlRef.current} thankYouPageUrl={thankYouPageUrlRef.current} */
            />
          }
        />
        <Notifier />
      </NotifierProvider>
    </ThemeProvider>
  );
};

export default SingleStepApply;
