import { css } from '@emotion/react';
import styled from '@emotion/styled';

import type { CaptionMediumProps } from './types';

export const StyledCaption = styled.span<Pick<CaptionMediumProps, 'disabled' | 'textCase'>>(
  ({ theme: { palette, typography }, disabled }) => css`
    ${typography.t1};
    color: ${disabled ? palette.text.disabled : palette.text.secondary};
  `,
);
