import type { ReactNode } from 'react';

import type TestType from 'types/testType';

export enum NoResultSize {
  Standard = 'standard',
  Compact = 'compact',
}

export type NoResultImageWrapperProps = {
  size?: NoResultSize;
};

export type NoResultImageProps = {
  height?: string;
};

export type NoResultProps = NoResultImageProps &
  NoResultImageWrapperProps &
  TestType & {
    onButtonClick?: () => void;
    title?: ReactNode;
    subtitle?: ReactNode;
    buttonContent?: ReactNode;
    imageUrl: string;
    backgroundImageUrl?: string;
  };
