import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import useOneStepApplicationFormQuery from 'api/query/useOneStepApplicationFormQuery';

import { UUID } from 'config/constants/queryParams';
import { TextColor } from 'types/theme/text';

import CaptionMedium from 'components/ui/atoms/text/CaptionMedium';
import PageTitle from 'components/ui/atoms/text/PageTitle';
import LoaderSpinner from 'components/ui/molecules/LoaderSpinner';

import { WelcomeTextContainer, Wrapper } from '../../styled';

type ApplyContentProps = {
  updateIframe: any;
  setErrors: any;
  /* termsOfServiceUrl: any;
  thankYouPageUrl: any; */
};

const ApplyContent = ({ updateIframe, setErrors /* termsOfServiceUrl, thankYouPageUrl */ }: ApplyContentProps) => {
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get(UUID);

  const { /* data, */ loading } = useOneStepApplicationFormQuery({
    skip: !uuid,
    // @ts-expect-error we skip if no uuid
    variables: { uuid },
    onCompleted: () => {
      setErrors(null);
    },
    onError: (errors) => {
      setErrors(errors);
    },
  });

  useEffect(() => {
    updateIframe();
  });

  if (loading && uuid) {
    return <LoaderSpinner />;
  }

  return (
    <>
      <WelcomeTextContainer>
        <PageTitle>Information Form</PageTitle>
        <CaptionMedium textColor={TextColor.Primary}>
          Thank you for placing your trust in us. Please take a few minutes to complete the form below. We will process
          your request right away.
        </CaptionMedium>
      </WelcomeTextContainer>

      <Wrapper>
        {/* <ApplyForm
          termsOfServiceUrl={termsOfServiceUrl}
          thankYouPageUrl={thankYouPageUrl}
          loading={loading && uuid}
          data={data}
        /> */}
      </Wrapper>
    </>
  );
};

export default ApplyContent;
