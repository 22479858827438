import SecondStepIcon from 'assets/icons/expired-link.svg';

import type { NoResultProps } from 'components/ui/molecules/NoResult';
import NoResult from 'components/ui/molecules/NoResult';

export type ExpiredLinkProps = {
  onClick: NoResultProps['onButtonClick'];
};

const ExpiredLink = ({ onClick }: ExpiredLinkProps) => {
  return (
    <NoResult
      imageUrl={SecondStepIcon}
      onButtonClick={onClick}
      title="Expired link"
      subtitle="Link to the application form you are trying to access expired"
      buttonContent="Apply now"
      data-testid="expired-link"
    />
  );
};

export default ExpiredLink;
