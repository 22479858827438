import { StyledCaption } from './styled';
import type { CaptionMediumProps } from './types';

const CaptionMedium = ({ children, disabled, textCase, dataTestId }: CaptionMediumProps) => (
  <StyledCaption disabled={disabled} textCase={textCase} data-testid={dataTestId}>
    {children}
  </StyledCaption>
);

export default CaptionMedium;
