import { css } from '@emotion/react';
import { styled as muiStyled } from '@mui/material/styles';

export const Wrapper = muiStyled('div')(
  css`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  `,
);

export const Header = muiStyled('div')(
  ({ theme: { spacing } }) => css`
    display: flex;
    align-items: center;
    padding: ${spacing(3, 7.5)};
    width: 100%;
  `,
);

export const LogoLink = muiStyled('a')(
  css`
    max-height: 48px;
    max-width: 100px;
  `,
);

export const MenuLink = muiStyled('a')(
  ({ theme: { palette } }) =>
    css`
      color: ${palette.secondary.dark};
    `,
);

export const Content = muiStyled('div')(
  ({
    theme: {
      breakpoints: { values: breakpoints, down },
      spacing,
    },
  }) => css`
    max-width: 720px;
    margin: ${spacing(5, 0)};
    flex-grow: 1;
    ${down(breakpoints.md)} {
      max-width: 100%;
      padding: ${spacing(0, 2)};
    }
  `,
);
