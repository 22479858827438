import { useParams, useSearchParams } from 'react-router-dom';

import { ORIGIN } from 'config/constants/queryParams';

import useCheckIfFrameMode from 'hooks/useCheckIfFrameMode';

export type GetReferralIdFromOrigin = (origin: string) => string;

const useReferralId = (getReferralIdFromOrigin: GetReferralIdFromOrigin): string | null => {
  const params = useParams();
  const { referralId } = params || {};
  const [searchParams] = useSearchParams();
  const origin = searchParams.get(ORIGIN);

  const iframeMode = useCheckIfFrameMode();

  const referralIdFromOrigin = iframeMode && origin && getReferralIdFromOrigin(origin);

  return referralId || referralIdFromOrigin || null;
};

export default useReferralId;
