import { css } from '@emotion/react';
import { styled as muiStyled } from '@mui/material/styles';

export const WelcomeTextContainer = muiStyled('div')(
  ({ theme: { spacing } }) => css`
    display: flex;
    flex-direction: column;
    text-align: center;
    row-gap: ${spacing(2)};
  `,
);

export const Wrapper = muiStyled('div')(
  ({ theme: { spacing } }) => css`
    margin: ${spacing(5, 0)};
  `,
);

export const AgreeBlock = muiStyled('div')(
  ({
    theme: {
      typography,
      spacing,
      palette,
      breakpoints: { values: breakpoints, down },
    },
  }) => css`
    width: auto;
    display: flex;
    align-items: center;
    column-gap: ${spacing(2)};
    ${typography.t3};
    line-height: 20px;

    a {
      color: ${palette.primary.main};

      &:hover {
        opacity: 0.5;
      }
    }

    ${down(breakpoints.md)} {
      margin-bottom: ${spacing(2)};
    }
  `,
);
