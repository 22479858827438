import type { ErrorProps } from '../types';
import ExpiredLink from './ExpiredLink';

const Error = ({ errors, setErrors, onClick }: ErrorProps) => {
  if (!errors) return null;

  const applyNow = () => {
    setErrors(null);
    onClick();
  };

  return <ExpiredLink onClick={applyNow} />;
};

export default Error;
