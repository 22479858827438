import FontWeight from 'types/theme/text/fontWeight';

import Button from 'components/ui/atoms/buttons/Button';
import CaptionMedium from 'components/ui/atoms/text/CaptionMedium';
import TitleLarge from 'components/ui/atoms/text/TitleLarge';

import { BackgroundImage, Container, NoResultImage, StyledImageWrapper } from './styled';
import type { NoResultProps } from './types';
import { NoResultSize } from './types';

const NoResult = ({
  imageUrl,
  onButtonClick,
  title,
  subtitle,
  buttonContent,
  backgroundImageUrl,
  size = NoResultSize.Standard,
  ...restProps
}: NoResultProps) => {
  return (
    <Container {...restProps}>
      <StyledImageWrapper size={size}>
        {backgroundImageUrl && <BackgroundImage src={backgroundImageUrl} />}
        <NoResultImage src={imageUrl} />
      </StyledImageWrapper>
      {title && <TitleLarge fontWeight={FontWeight.Bold}>{title}</TitleLarge>}
      {subtitle && <CaptionMedium>{subtitle}</CaptionMedium>}
      {onButtonClick && (
        <Button variant="contained" size="large" disableRipple disableElevation onClick={onButtonClick}>
          {buttonContent}
        </Button>
      )}
    </Container>
  );
};

export default NoResult;
