import { css } from '@emotion/react';
import { styled as muiStyled } from '@mui/material/styles';

import type { NoResultImageProps, NoResultImageWrapperProps } from './types';
import { NoResultSize } from './types';

export const Container = muiStyled('div')(
  ({ theme: { spacing } }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${spacing(3, 0)};
    gap: ${spacing(2)};
    text-align: center;
  `,
);

export const StyledImageWrapper = muiStyled('div')<NoResultImageWrapperProps>(
  ({
    theme: {
      breakpoints: { values: breakpoints, down },
    },
    size,
  }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    ${size === NoResultSize.Compact
      ? css`
          height: 125px;
        `
      : css`
          height: 200px;
          ${down(breakpoints.md)} {
            height: 100px;
          }
        `}
  `,
);

export const NoResultImage = muiStyled('img')(
  ({ height }: NoResultImageProps) => css`
    position: relative;
    height: ${height || '100%'};
  `,
);

export const BackgroundImage = muiStyled('img')(
  css`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  `,
);
